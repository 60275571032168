// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("jquery");
import './sentry';

import React, { lazy, Suspense }  from 'react'
import ReactDOM from 'react-dom'
import ConsentReminderModal from '../components/consent_modal/consent_reminder_modal';

require("packs/layouts/navbar");

window.LazyLoad = require("vanilla-lazyload");


var lazyload_callback_loaded = function (element) {
  if (element.classList.contains('video')) {
    Object.keys(element.dataset).forEach(dataKey => {
      if (dataKey.includes('gtmYtInspected')) delete element.dataset[dataKey];
    });
  }
};

window.addEventListener('DOMContentLoaded', function() {
  window.lazyLoadOptions = {
    threshold: 0,
    callback_loaded: lazyload_callback_loaded,
  };

  var lazyLoadInstance = new LazyLoad(window.lazyLoadOptions);
  lazyLoadInstance.update();

  const elem = document.getElementById('consent-modal');

  ReactDOM.render(
    <ConsentReminderModal />,
    elem
  );
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
